import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from "../Assets/logo.png";
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Footer.css'

AOS.init();

function Footer() {
  function scrollTop() {
    window.scrollTo(0, 0)
  }

  return (
    <div className= 'footer' >
      <div className="footer__container">
        <button className='footer__logo' onClick={scrollTop}>
          <img style={{maxHeight: '50px'}} className='invert' src={logo} />
        </button>
        <p className='footer__p'>Get in Touch!</p>
        <p>Need help building or improving your website? Don't hesitate to contact me on the links below!</p>
        <div className="footer__row">
          <div className='footer__line'/>
          <div className="footer--icons">
            <Link to="https://github.com/LPedroSF" target="_blank" className="footer--icons click">
              <FontAwesomeIcon icon="fa-brands fa-github" />
            </Link>
            <Link target="_blank" to="https://www.linkedin.com/in/luis-faria-814824224/" className="footer--icons click">
              <FontAwesomeIcon icon="fa-brands fa-linkedin" />
            </Link>
            <Link target="_blank" to="mailto:lpedrosf02@gmail.com" className="footer--icons click">
              <FontAwesomeIcon icon="fa-solid fa-envelope" />
            </Link>
          </div>
          <div className='footer__line'/>
        </div>
        <div className="footer__text">
          <p>Copyright © 2024 LPSF</p>
        </div>
      </div>
    </div>
  )
}

export default Footer