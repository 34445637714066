import React from 'react';
import TechBox from './UI/TechBox';
import './Tech.css'
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

function Tech() {
  return (
    <div className="tech new__section">
      <h1 className='title'>Now the important stuff...</h1>
      <div className='lists'>
        <div className="list">
          <div className="designs">
            <div className="line" />
            <h2>Languages</h2>
            <div className="line" />
          </div>
          <div className="list__items">
            <div data-aos='fade-left'><TechBox name = 'Python'/></div>
            <div data-aos='fade-left' data-aos-delay='100'><TechBox name = 'Java'/></div>
            <div data-aos='fade-left' data-aos-delay='200'><TechBox name = 'JavaScript'/></div>
            <div data-aos='fade-left' data-aos-delay='300'><TechBox name = 'React'/></div>
            <div data-aos='fade-left' data-aos-delay='400'><TechBox name = 'CSS'/></div>
            <div data-aos='fade-left' data-aos-delay='600'><TechBox name = 'C#'/></div>
          </div>
        </div>
        <div className="list">
          <div className="designs">
            <div className="line" />
            <h2>Programs</h2>
            <div className="line" />
          </div>
          <div className="list__items">
            <div data-aos='fade-left'><TechBox  name = 'Firebase'/></div>
            <div data-aos='fade-left' data-aos-delay='100'><TechBox name = 'Git'/></div>
            <div data-aos='fade-left' data-aos-delay='200'><TechBox name = 'REST APIs'/></div>
            <div data-aos='fade-left' data-aos-delay='300'><TechBox name = '.NET'/></div>
          </div>
        </div>
      </div>
    </div>
    
  )
}

export default Tech;