import React, {useState, useEffect} from 'react';
import './Contacts.css';
import bike from '../Assets/bike.png';
import car from '../Assets/car.png';
import car2 from '../Assets/car-2.png';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

function Contacts() {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.onscroll = () => {
      setScrolled(true);
    }
  }, [])
  
  return (
    <div className="road">
      <div className='contacts'>
        <div className="links__box">
          <Link to='https://github.com/LPedroSF' target='_blank' className='links'>
            <FontAwesomeIcon icon="fa-brands fa-github" />
          </Link>
          <Link to='https://www.linkedin.com/in/luis-faria-814824224/' target='_blank' className='links'>
            <FontAwesomeIcon icon="fa-brands fa-linkedin" />
          </Link>
          <Link to='mailto=lpedrosf02@gmail.com' target='_blank' className='links'>
            <FontAwesomeIcon icon="fa-solid fa-envelope" />
          </Link>
        </div>
        <div className="long-line"></div>
      </div>
      <div className={ scrolled ? 'imgs__container hide' : 'imgs__container '}>
        <div><img className='imgs' src={ car } alt='bike'/></div>
        <div><img className='imgs' src={ car2 } alt='car'/></div>
        <div><img className='imgs bike' src={ bike } alt='car2'/></div>
      </div>
    </div>
    
  )
}

export default Contacts